import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Projects from '../components/projects'

const Fun = () => {
  const {
    allContentfulProject: { edges: projects },
  } = useStaticQuery(graphql`
    {
      allContentfulProject(filter: { projectType: { in: ["Fun"] } }) {
        edges {
          node {
            id
            title
            menuTitle
            description {
              description
            }
            thumbnail {
              gatsbyImageData(width: 500, layout: CONSTRAINED)
              description
            }
          }
        }
      }
    }
  `)

  return <Projects type="Fun" projects={projects} />
}

export default Fun
